import * as _readline2 from "readline";

var _readline = "default" in _readline2 ? _readline2.default : _readline2;

import _process from "process";
var exports = {};
var process = _process;
const readline = _readline;

function ask(question) {
  const rl = readline.createInterface({
    input: process.stdin,
    output: process.stdout
  });
  return new Promise(resolve => {
    rl.question(question, answer => {
      rl.close();
      resolve(answer);
    });
  });
}

async function confirm(question, rejectionMessage, expectedAnswers = ["yes", "y"]) {
  const answer = await ask(question);

  if (!expectedAnswers.includes(answer)) {
    throw new Error(rejectionMessage);
  }

  return true;
}

exports = {
  confirm
};
export default exports;