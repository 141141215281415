import * as _fs2 from "fs";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

import * as _commonEnv2 from "common-env";

var _commonEnv = "default" in _commonEnv2 ? _commonEnv2.default : _commonEnv2;

import * as _mkdirp2 from "mkdirp";

var _mkdirp = "default" in _mkdirp2 ? _mkdirp2.default : _mkdirp2;

import * as _xdg2 from "xdg";

var _xdg = "default" in _xdg2 ? _xdg2.default : _xdg2;

import _logger from "../logger.js";
import _process from "process";
var exports = {};
var process = _process;
const {
  promises: fs
} = _fs;
const path = _path;
const commonEnv = _commonEnv;
const mkdirp = _mkdirp;
const xdg = _xdg;
const Logger = _logger;
const env = commonEnv(Logger);

function getConfigDir() {
  if (process.platform === "win32") {
    return path.resolve(process.env.APPDATA, "clever-cloud");
  } else {
    return xdg.basedir.configPath("clever-cloud");
  }
}

function getConfigPath() {
  const configDir = getConfigDir();
  return path.resolve(configDir, "clever-tools.json");
}

async function isFile(path) {
  try {
    const pathStat = await fs.stat(path);
    return pathStat.isFile();
  } catch (e) {
    return false;
  }
}

async function maybeMigrateFromLegacyConfigurationPath() {
  // This used to be a file
  const configDir = getConfigDir();
  const legacyConfig = await isFile(configDir); // If it is still a file, we replace it with a dir and move it inside

  if (legacyConfig) {
    const tmpConfigFile = `${configDir}.tmp`;
    const configFile = getConfigPath(); // Rename so that we can create the directory

    await fs.rename(configDir, tmpConfigFile);
    await mkdirp(configDir, {
      mode: 448
    });
    await fs.rename(tmpConfigFile, configFile);
  }
}

async function loadOAuthConf() {
  Logger.debug("Load configuration from environment variables");

  if (process.env.CLEVER_TOKEN != null && process.env.CLEVER_SECRET != null) {
    return {
      token: process.env.CLEVER_TOKEN,
      secret: process.env.CLEVER_SECRET
    };
  }

  Logger.debug("Load configuration from " + conf.CONFIGURATION_FILE);
  await maybeMigrateFromLegacyConfigurationPath();

  try {
    const rawFile = await fs.readFile(conf.CONFIGURATION_FILE);
    return JSON.parse(rawFile);
  } catch (error) {
    Logger.info(`Cannot load configuration from ${conf.CONFIGURATION_FILE}\n${error.message}`);
    return {};
  }
}

async function writeOAuthConf(oauthData) {
  Logger.debug("Write the tokens in the configuration file\u2026");
  const configDir = path.dirname(conf.CONFIGURATION_FILE);

  try {
    await mkdirp(configDir, {
      mode: 448
    });
    await fs.writeFile(conf.CONFIGURATION_FILE, JSON.stringify(oauthData));
  } catch (error) {
    throw new Error(`Cannot write configuration to ${conf.CONFIGURATION_FILE}\n${error.message}`);
  }
}

const conf = env.getOrElseAll({
  API_HOST: "https://api.clever-cloud.com",
  // API_HOST: 'https://ccapi-preprod.cleverapps.io',
  LOG_WS_URL: "wss://api.clever-cloud.com/v2/logs/logs-socket/<%- appId %>?since=<%- timestamp %>",
  LOG_HTTP_URL: "https://api.clever-cloud.com/v2/logs/<%- appId %>",
  EVENT_URL: "wss://api.clever-cloud.com/v2/events/event-socket",
  WARP_10_EXEC_URL: "https://c1-warp10-clevercloud-customers.services.clever-cloud.com/api/v0/exec",
  OAUTH_CONSUMER_KEY: "T5nFjKeHH4AIlEveuGhB5S3xg8T19e",
  OAUTH_CONSUMER_SECRET: "MgVMqTr6fWlf2M0tkC2MXOnhfqBWDT",
  SSH_GATEWAY: "ssh@sshgateway-clevercloud-customers.services.clever-cloud.com",
  CONFIGURATION_FILE: getConfigPath(),
  CONSOLE_TOKEN_URL: "https://console.clever-cloud.com/cli-oauth",
  // CONSOLE_TOKEN_URL: 'https://next-console.cleverapps.io/cli-oauth',
  CLEVER_CONFIGURATION_DIR: path.resolve(".", "clevercloud"),
  APP_CONFIGURATION_FILE: path.resolve(".", ".clever.json")
});
exports = {
  conf,
  loadOAuthConf,
  writeOAuthConf
};
export default exports;