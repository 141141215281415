import * as _organisation2 from "@clevercloud/client/cjs/api/v2/organisation.js";

var _organisation = "default" in _organisation2 ? _organisation2.default : _organisation2;

import _sendToApi from "../models/send-to-api.js";
var exports = {};
const {
  get
} = _organisation;
const {
  sendToApi
} = _sendToApi;

function getCurrent() {
  return get({}).then(sendToApi);
}

;

function getCurrentId() {
  return get({}).then(sendToApi).then(({
    id
  }) => id);
}

;
exports = {
  getCurrent,
  getCurrentId
};
export default exports;