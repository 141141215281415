import _logger from "../logger.js";
import * as _oauthNode2 from "@clevercloud/client/cjs/oauth.node.js";

var _oauthNode = "default" in _oauthNode2 ? _oauthNode2.default : _oauthNode2;

import _configuration from "../models/configuration.js";
import * as _requestWarp10Superagent2 from "@clevercloud/client/cjs/request-warp10.superagent.js";

var _requestWarp10Superagent = "default" in _requestWarp10Superagent2 ? _requestWarp10Superagent2.default : _requestWarp10Superagent2;

import * as _prefixUrl2 from "@clevercloud/client/cjs/prefix-url.js";

var _prefixUrl = "default" in _prefixUrl2 ? _prefixUrl2.default : _prefixUrl2;

import * as _requestSuperagent2 from "@clevercloud/client/cjs/request.superagent.js";

var _requestSuperagent = "default" in _requestSuperagent2 ? _requestSuperagent2.default : _requestSuperagent2;

import _process from "process";
var exports = {};
var process = _process;
const Logger = _logger;
const {
  addOauthHeader
} = _oauthNode;
const {
  conf,
  loadOAuthConf
} = _configuration;
const {
  execWarpscript
} = _requestWarp10Superagent;
const {
  prefixUrl
} = _prefixUrl;
const {
  request
} = _requestSuperagent;

async function loadTokens() {
  const tokens = await loadOAuthConf();
  return {
    OAUTH_CONSUMER_KEY: conf.OAUTH_CONSUMER_KEY,
    OAUTH_CONSUMER_SECRET: conf.OAUTH_CONSUMER_SECRET,
    API_OAUTH_TOKEN: tokens.token,
    API_OAUTH_TOKEN_SECRET: tokens.secret
  };
}

async function sendToApi(requestParams) {
  const tokens = await loadTokens();
  return Promise.resolve(requestParams).then(prefixUrl(conf.API_HOST)).then(addOauthHeader(tokens)).then(requestParams => {
    if (process.env.CLEVER_VERBOSE) {
      Logger.debug(`${requestParams.method.toUpperCase()} ${requestParams.url} ? ${JSON.stringify(requestParams.queryParams)}`);
    }

    return requestParams;
  }).then(requestParams => request(requestParams, {
    retry: 1
  }));
}

function sendToWarp10(requestParams) {
  return Promise.resolve(requestParams).then(prefixUrl(conf.WARP_10_EXEC_URL)).then(requestParams => execWarpscript(requestParams, {
    retry: 1
  }));
}

async function getHostAndTokens() {
  const tokens = await loadTokens();
  return {
    apiHost: conf.API_HOST,
    tokens
  };
}

exports = {
  sendToApi,
  sendToWarp10,
  getHostAndTokens
};
export default exports;