export default {
  "name": "clever-tools",
  "version": "2.9.1",
  "description": "Command Line Interface for Clever Cloud.",
  "main": "bin/clever.js",
  "keywords": [
    "clever-tools",
    "cli",
    "clever cloud"
  ],
  "engines": {
    "node": ">=12"
  },
  "pkg-node-version": "12",
  "author": "Clever Cloud <ci@clever-cloud.com>",
  "license": "Apache-2.0",
  "bin": {
    "clever": "./bin/clever.js",
    "install-clever-completion": "./scripts/install-autocomplete.sh",
    "uninstall-clever-completion": "./scripts/uninstall-autocomplete.sh"
  },
  "files": [
    "bin",
    "src",
    "scripts/*.sh"
  ],
  "dependencies": {
    "@clevercloud/client": "^7.4.0",
    "clf-date": "^0.2.0",
    "cliparse": "^0.3.3",
    "colors": "1.4.0",
    "common-env": "^6.4.0",
    "eventsource": "^1.1.0",
    "isomorphic-git": "^1.8.2",
    "linux-release-info": "^3.0.0",
    "lodash": "^4.17.21",
    "mkdirp": "^1.0.4",
    "moment": "^2.29.1",
    "open": "^8.4.0",
    "slugify": "^1.5.3",
    "string-length": "^4.0.2",
    "superagent": "^6.1.0",
    "text-table": "^0.2.0",
    "update-notifier": "^5.1.0",
    "ws": "^7.4.6",
    "xdg": "^0.1.1"
  },
  "devDependencies": {
    "aws-sdk": "^2.919.0",
    "chai": "^4.3.4",
    "del": "^6.0.0",
    "eslint": "^7.27.0",
    "eslint-config-standard": "^16.0.3",
    "eslint-plugin-import": "^2.23.4",
    "eslint-plugin-node": "^11.1.0",
    "eslint-plugin-promise": "^5.1.0",
    "eslint-plugin-standard": "^5.0.0",
    "fs-extra": "^10.0.0",
    "glob": "^7.1.7",
    "grunt": "^1.4.1",
    "grunt-cli": "^1.4.3",
    "grunt-http": "^2.3.3",
    "grunt-mocha-test": "^0.13.3",
    "mocha": "^8.4.0",
    "pkg": "^5.2.1",
    "semver": "^7.3.5"
  },
  "scripts": {
    "pretest": "npm run lint",
    "test": "grunt test",
    "lint": "eslint bin src scripts",
    "lint:fix": "eslint --fix bin src scripts"
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/CleverCloud/clever-tools.git"
  },
  "bugs": {
    "url": "https://github.com/CleverCloud/clever-tools/issues"
  },
  "homepage": "https://github.com/CleverCloud/clever-tools",
  "pkg": {
    "scripts": [
      "src/**/*.js"
    ]
  },
  "volta": {
    "node": "12.22.8",
    "npm": "6.14.15"
  }
}
;