import * as _fs2 from "fs";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

import * as _lodash2 from "lodash";

var _lodash = "default" in _lodash2 ? _lodash2.default : _lodash2;

import * as _isomorphicGit2 from "isomorphic-git";

var _isomorphicGit = "default" in _isomorphicGit2 ? _isomorphicGit2.default : _isomorphicGit2;

import * as _node2 from "isomorphic-git/http/node";

var _node = "default" in _node2 ? _node2.default : _node2;

import * as _cliparse2 from "cliparse";

var _cliparse = "default" in _cliparse2 ? _cliparse2.default : _cliparse2;

import * as _slugify2 from "slugify";

var _slugify = "default" in _slugify2 ? _slugify2.default : _slugify2;

import _fsUtils from "./fs-utils.js";
import _configuration from "./configuration.js";
var exports = {};
const fs = _fs;
const path = _path;
const _ = _lodash;
const git = _isomorphicGit;
const http = _node;
const {
  autocomplete
} = _cliparse;
const slugify = _slugify;
const {
  findPath
} = _fsUtils;
const {
  loadOAuthConf
} = _configuration;

async function getRepo() {
  try {
    const dir = await findPath(".", ".git");
    return {
      fs,
      dir,
      http
    };
  } catch (e) {
    throw new Error("Could not find the .git folder.");
  }
}

async function onAuth() {
  const tokens = await loadOAuthConf();
  return {
    username: tokens.token,
    password: tokens.secret
  };
}

async function addRemote(remoteName, url) {
  const repo = await getRepo();
  const safeRemoteName = slugify(remoteName);
  const allRemotes = await git.listRemotes({ ...repo
  });

  const existingRemote = _.find(allRemotes, {
    remote: safeRemoteName
  });

  if (existingRemote == null) {
    // In some situations, we may end up with race conditions so we force it
    return git.addRemote({ ...repo,
      remote: safeRemoteName,
      url,
      force: true
    });
  }
}

async function resolveFullCommitId(commitId) {
  if (commitId == null) {
    return null;
  }

  try {
    const repo = await getRepo();
    return await git.expandOid({ ...repo,
      oid: commitId
    });
  } catch (e) {
    if (e.code === "ShortOidNotFound") {
      throw new Error(`Commit id ${commitId} is ambiguous`);
    }

    throw e;
  }
}

async function getRemoteCommit(remoteUrl) {
  const repo = await getRepo();
  const remoteInfos = await git.getRemoteInfo({ ...repo,
    onAuth,
    url: remoteUrl
  });
  return _.get(remoteInfos, "refs.heads.master");
}

async function getFullBranch(branchName) {
  const repo = await getRepo();

  if (branchName === "") {
    const currentBranch = await git.currentBranch({ ...repo,
      fullname: true
    });
    return currentBranch || "HEAD";
  }

  return git.expandRef({ ...repo,
    ref: branchName
  });
}

;

async function getBranchCommit(refspec) {
  const repo = await getRepo();
  return git.resolveRef({ ...repo,
    ref: refspec
  });
}

async function push(remoteUrl, branchRefspec, force) {
  const repo = await getRepo();

  try {
    const push = await git.push({ ...repo,
      onAuth,
      url: remoteUrl,
      ref: branchRefspec,
      remoteRef: "master",
      force
    });

    if (push.errors != null) {
      throw new Error(push.errors.join(", "));
    }

    return push;
  } catch (e) {
    if (e.code === "PushRejectedNonFastForward") {
      throw new Error("Push rejected because it was not a simple fast-forward. Use \"--force\" to override.");
    }

    throw e;
  }
}

function completeBranches() {
  return getRepo().then(repo => git.listBranches(repo)).then(autocomplete.words);
}

async function isShallow() {
  const {
    dir
  } = await getRepo();

  try {
    await fs.promises.access(path.join(dir, ".git", "shallow"));
    return true;
  } catch (e) {
    return false;
  }
}

exports = {
  addRemote,
  resolveFullCommitId,
  getRemoteCommit,
  getFullBranch,
  getBranchCommit,
  push,
  completeBranches,
  isShallow
};
export default exports;