import _logger from "./logger.js";
import _package from "../package.json";
import * as _semver2 from "semver";

var _semver = "default" in _semver2 ? _semver2.default : _semver2;

import _process from "process";
var exports = {};
var process = _process;
const Logger = _logger;
const pkg = _package;
const semver = _semver;

function handleCommandPromise(promise) {
  promise.catch(error => {
    Logger.error(error);
    const semverIsOk = semver.satisfies(process.version, pkg.engines.node);

    if (!semverIsOk) {
      Logger.warn(`You are using node ${process.version}, some of our commands require node ${pkg.engines.node}. The error may be caused by this.`);
    }

    process.exit(1);
  });
}

exports = handleCommandPromise;
export default exports;