import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

import * as _fs2 from "fs";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

var exports = {};
const {
  join
} = _path;
const {
  promises: fs
} = _fs;

function findPath(dir, name) {
  const fullPath = join(dir, name);
  return fs.stat(fullPath).then(() => dir).catch(e => {
    if (e.code === "ENOENT" && dir !== "/") {
      const parent = join(dir, "..");
      return findPath(parent, name);
    }

    throw e;
  });
}

exports = {
  findPath
};
export default exports;