import * as _lodash2 from "lodash";

var _lodash = "default" in _lodash2 ? _lodash2.default : _lodash2;

import * as _cliparse2 from "cliparse";

var _cliparse = "default" in _cliparse2 ? _cliparse2.default : _cliparse2;

import _app_configuration from "./app_configuration.js";
import * as _organisation2 from "@clevercloud/client/cjs/api/v2/organisation.js";

var _organisation = "default" in _organisation2 ? _organisation2.default : _organisation2;

import * as _user2 from "@clevercloud/client/cjs/api/v2/user.js";

var _user = "default" in _user2 ? _user2.default : _user2;

import _sendToApi from "../models/send-to-api.js";
var exports = {};
const _ = _lodash;
const autocomplete = _cliparse.autocomplete;
const AppConfig = _app_configuration;
const organisation = _organisation;
const {
  getSummary
} = _user;
const {
  sendToApi
} = _sendToApi;

async function getId(orgaIdOrName) {
  if (orgaIdOrName == null) {
    return null;
  }

  if (orgaIdOrName.orga_id != null) {
    return orgaIdOrName.orga_id;
  }

  return getByName(orgaIdOrName.orga_name).then(orga => orga.id);
}

async function getByName(name) {
  const fullSummary = await getSummary({}).then(sendToApi);

  const filteredOrgs = _.filter(fullSummary.organisations, {
    name
  });

  if (filteredOrgs.length === 0) {
    throw new Error("Organisation not found");
  }

  if (filteredOrgs.length > 1) {
    throw new Error("Ambiguous organisation name");
  }

  return filteredOrgs[0];
}

async function getNamespaces(params) {
  const {
    alias
  } = params.options;
  const {
    ownerId
  } = await AppConfig.getAppDetails({
    alias
  });
  return organisation.getNamespaces({
    id: ownerId
  }).then(sendToApi);
}

function completeNamespaces() {
  // Sadly we do not have access to current params in complete as of now
  const params = {
    options: {}
  };
  return getNamespaces(params).then(autocomplete.words);
}

;
exports = {
  getId,
  getNamespaces,
  completeNamespaces
};
export default exports;